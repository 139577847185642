<template>
  <div><module-list></module-list></div>
</template>

<script>
import moduleList from '../../components/general/moduleList.vue'
// import layoutDatabase from "../../layouts/layout-database.vue";
import layoutHome from '../../layouts/layout-home.vue'
export default {
  components: { moduleList },
  created() {
    this.$emit('update:layout', layoutHome)
  }
}
</script>

<style></style>
